import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import CECYTEJ from "../logos/CECYTEJ.png";
import "./Header.css";

const Header = ({
  alumno,
  modificarAlumno,
  login,
  cambiarLogin,
  cambiarChecked,
  cambiarPagar,
  cambiarPrecio,
  cambiarClaves,
}) => {
  let history = useHistory();
  const onClickLogout = () => {
    modificarAlumno({ codigo: "", nombre: "" });
    cambiarLogin(false);
    cambiarPagar(false);
    cambiarPrecio(0);
    cambiarClaves([]);
    cambiarChecked({
      c1: false,
      c2: false,
      c3: false,
      c4: false,
      c5: false,
      c6: false,
      c7: false,
      c8: false,
      c9: false,
      c10: false,
      c11: false,
      c12: false,
      c13: false,
      c14: false,
      c15: false,
      c16: false,
      c17: false,
      c18: false,
      c19: false,
      c20: false,
      c21: false,
    });
    history.push("/");
  };

  return (
    <header className="Header">
      <div className="container flex">
        <div className="logo">
          <img src={CECYTEJ} alt="Logo" />
          <h1 className="">Sistema de Pagos</h1>
        </div>
        {login > 0 ? (
          <div className="Header-alumno">
            <p>Hola, {alumno.nombre}</p>
            <a className="Header-logout" onClick={onClickLogout}>
              Cambiar Alumno
            </a>
          </div>
        ) : null}
      </div>
      <hr />
    </header>
  );
};

export default Header;
