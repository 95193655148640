import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import "./FormularioExamen.css";
import ReferenciaOnline from "../utilities/ReferenciaOnline";

const FormularioDiplomas = ({
  alumno,
  precio,
  cambiarPrecio,
  cambiarDatosPago,
  cambiarTablaConceptos,
}) => {
  const [checked, cambiarChecked] = useState({ diplomas: "0" });
  const [error, cambiarError] = useState(false);
  const [pagar, cambiarPagar] = useState(false);

  const HandleOnChange = (e) => {
    cambiarChecked({ ...checked, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    cambiarPrecio(0);
    cambiarTablaConceptos([]);
  }, []);

  useEffect(() => {
    let total = 50 * parseInt(checked.diplomas);
    cambiarPrecio(total);
  }, [checked]);

  const CrearPago = async () => {
    if (precio === 0) {
      cambiarError(true);
      return;
    }
    let tabla = [];
    let conceptos = "";
    if (checked.diplomas > 0) {
      for (let i = 0; i < checked.diplomas; i++) {
        conceptos = conceptos.concat("11");
        tabla.push({
          concepto: "Diploma de competencias",
          clave: "11",
          precio: "50",
        });
      }
    }
    if (conceptos.length < 10) {
      while (conceptos.length < 10) {
        conceptos = conceptos.concat("00");
      }
    }

    cambiarTablaConceptos(tabla);

    //Examen extraordinario clave 15
    //Curso Intersemestral clave 27
    cambiarError(false);

    //Armar referencia
    let importe = precio.toString().concat(".00");
    let refaux = alumno.codigo[7].concat(alumno.codigo[8]);
    refaux = refaux.concat(conceptos);
    refaux = refaux.concat(alumno.codigo);
    let base64 = await ReferenciaOnline(refaux, importe);
    cambiarDatosPago({
      referencia: refaux,
      importe: importe,
      key: base64,
    });

    cambiarPagar(true);
  };

  return alumno.codigo === "" ? (
    <Redirect to="/login" />
  ) : (
    <div className="FormularioExamen container">
      <h2>Selecciona la cantidad de diplomas</h2>
      <div className="FormularioExamen-flex">
        <div className="FormularioExamen-form">
          <div className="FormularioExamen-examen">
            <p>Diplomas de Competencias</p>
            <label>
              <input
                type="radio"
                name="diplomas"
                value="0"
                checked={checked.diplomas === "0"}
                onChange={HandleOnChange}
              />
              0
            </label>
            <label>
              <input
                type="radio"
                name="diplomas"
                value="1"
                checked={checked.diplomas === "1"}
                onChange={HandleOnChange}
              />
              1
            </label>
            <label>
              <input
                type="radio"
                name="diplomas"
                value="2"
                checked={checked.diplomas === "2"}
                onChange={HandleOnChange}
              />
              2
            </label>
            <label>
              <input
                type="radio"
                name="diplomas"
                value="3"
                checked={checked.diplomas === "3"}
                onChange={HandleOnChange}
              />
              3
            </label>
            <label>
              <input
                type="radio"
                name="diplomas"
                value="4"
                checked={checked.diplomas === "4"}
                onChange={HandleOnChange}
              />
              4
            </label>
            <label>
              <input
                type="radio"
                name="diplomas"
                value="5"
                checked={checked.diplomas === "5"}
                onChange={HandleOnChange}
              />
              5
            </label>
          </div>
        </div>
        <div className="">
          <div className="FormularioExamen-total">
            <h4>Total: ${precio}.00</h4>
          </div>
          {error ? (
            <div className="Formulario-error">
              <p>Selecciona una opción</p>
            </div>
          ) : null}
          <input
            className="Formulario-boton"
            type="button"
            onClick={CrearPago}
            value="Pagar"
          />
        </div>
      </div>
      {pagar ? <Redirect to="/formadepago" /> : null}
    </div>
  );
};

export default FormularioDiplomas;
