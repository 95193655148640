import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import ficha from "../img/ficha.png";

const styles = StyleSheet.create({
  page: {
    margin: "0",
    padding: "0",
    //color: "gray"
  },

  back: {
    zIndex: "-1",
    position: "absolute",
    Width: "100%",
    height: "100%",
  },

  name: { position: "absolute", top: "5cm", left: "5.75cm", fontSize: "10px" },
  campus: {
    position: "absolute",
    top: "6.7cm",
    left: "5.75cm",
    fontSize: "10px",
  },
  code: {
    position: "absolute",
    top: "6.7cm",
    left: "13.3cm",
    fontSize: "10px",
  },

  convenioBBVA: {
    position: "absolute",
    top: "10.7cm",
    left: "1.5cm",
    fontSize: "8px",
  },

  convenioBanorte: {
    position: "absolute",
    top: "12.4cm",
    left: "1.5cm",
    fontSize: "8px",
  },

  section: {
    position: "absolute",
    top: "12cm",
    left: "5.75cm",
    fontSize: "8px",
    width: "14.5cm",
  },

  section2: {
    display: "flex",
    justifyContent: "space-betwen",
    flexDirection: "row",
    marginBottom: "5px",
  },

  section2Titles: {
    display: "flex",
    justifyContent: "space-betwen",
    marginBottom: "10px",
    flexDirection: "row",
    color: "#bbbdbf",
    textTransform: "uppercase",
  },

  concepto: {
    width: "4cm",
    marginLeft: "10px",
  },

  referencia: {
    width: "4cm",
    textAlign: "left",
  },

  precio: {
    width: "1.5cm",
    textAlign: "left",
  },
});

const Pdf = ({ name, code, table }) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <Image src={ficha} style={styles.back} />

        <Text style={styles.name}>{name}</Text>
        <Text style={styles.campus}>{code[7].concat(code[8])}</Text>
        <Text style={styles.code}>{code}</Text>
        <Text style={styles.convenioBBVA}>
          {/* CIE: 1806998 */}
        </Text>
        <Text style={styles.convenioBanorte}>
          {/* Servicio 5446 */}
        </Text>

        <View style={styles.section}>
          <View style={styles.section2Titles}>
            <Text style={styles.concepto}>Concepto</Text>
            <Text style={styles.referencia}>
              {/* Referencia BBVA */}
            </Text>
            <Text style={styles.referencia}>Referencia</Text>
            <Text style={styles.precio}>Monto</Text>
          </View>

          {table.map((concepto) => (
            <View style={styles.section2}>
              <Text style={styles.concepto}>{concepto.concepto}</Text>
              <Text style={styles.referencia}>
                {/* {concepto.referencias.BBVA} */}
                </Text>
              <Text style={styles.referencia}>
                {concepto.referencias.Banorte}
              </Text>
              <Text style={styles.precio}>
                $
                {concepto.precio % 1 === 0
                  ? concepto.precio + ".00"
                  : concepto.precio}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default Pdf;
