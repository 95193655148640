const DigitoverBBVA = ({ monto, referencia }) => {
  let digito = 0;

  let cantidad = 0;

  const calcularCantidad = () => {
    const montolength = monto.length;
    let multiplicador = 0;

    for (let i = 0; i < montolength; i++) {
      switch (multiplicador) {
        case 0: {
          cantidad = cantidad + monto[monto.length - i - 1] * 1;
          break;
        }
        case 1: {
          cantidad = cantidad + monto[monto.length - i - 1] * 7;
          break;
        }
        case 2: {
          cantidad = cantidad + monto[monto.length - i - 1] * 3;
          break;
        }
      }
      multiplicador++;
      if (multiplicador === 3) {
        multiplicador = 0;
      }
    }
    cantidad = cantidad % 10;
  };

  calcularCantidad();

  referencia = referencia.concat(cantidad.toString());
  const digitoaux = referencia.slice(referencia.length - 13, referencia.length);

  let j = 0;
  for (let i = 1; i <= digitoaux.length; i++) {
    j++;
    if (j === 5) {
      digito = digito + digitoaux[digitoaux.length - i] * 23;
      j = 0;
      continue;
    }
    if (j === 4) {
      digito = digito + digitoaux[digitoaux.length - i] * 19;
      continue;
    }
    if (j === 3) {
      digito = digito + digitoaux[digitoaux.length - i] * 17;
      continue;
    }
    if (j === 2) {
      digito = digito + digitoaux[digitoaux.length - i] * 13;
      continue;
    }
    digito = digito + digitoaux[digitoaux.length - i] * 11;
  }

  digito = ((digito % 97) + 1).toString();
  if (digito.length === 1) {
    digito = "0".concat(digito);
  }
  referencia = referencia.concat(digito);

  return referencia;
};

export default DigitoverBBVA;
