const conceptos = [
  {
    id: "l01",
    concepto: "Libros Paquete semestre 2",
    precio: 1210,
    clave: "99",
  },
  {
    id: "l02",
    concepto: "Libros Paquete semestre 4",
    precio: 1020,
    clave: "98",
  },
  {
    id: "l03",
    concepto: "Conservación de la energía y sus interacciones con la materia",
    precio: 180,
    clave: "97",
  },
  {
    id: "l04",
    concepto:
      "Reacciones Químicas, Conservación de la materia en la formación de nuevas sustancias",
    precio: 180,
    clave: "97",
  },
  {
    id: "l05",
    concepto: "Temas selectos de matemáticas I",
    precio: 180,
    clave: "97",
  },
  {
    id: "l06",
    concepto: "Cultura digital II",
    precio: 200,
    clave: "96",
  },
  {
    id: "l07",
    concepto: "Lengua y comunicación II",
    precio: 200,
    clave: "96",
  },
  {
    id: "l08",
    concepto: "Emprende el Derecho",
    precio: 200,
    clave: "96",
  },
  {
    id: "l09",
    concepto: "Emprende los Temas de administración",
    precio: 200,
    clave: "96",
  },
  {
    id: "l10",
    concepto: "Emprende los Temas de Ciencias Sociales",
    precio: 200,
    clave: "96",
  },
  {
    id: "l11",
    concepto: "Temas Selectos de Filosofía",
    precio: 200,
    clave: "96",
  },
  {
    id: "l12",
    concepto: "Ciencias sociales II",
    precio: 210,
    clave: "95",
  },
  {
    id: "l13",
    concepto: "Inglés II",
    precio: 210,
    clave: "95",
  },
  {
    id: "l14",
    concepto: "Pensamiento matemático II",
    precio: 210,
    clave: "95",
  },
  {
    id: "l15",
    concepto: "Ciencias Sociales III",
    precio: 210,
    clave: "95",
  },
  {
    id: "l16",
    concepto: "Inglés IV",
    precio: 210,
    clave: "95",
  },
  {
    id: "l17",
    concepto: "Introducción a la Economía",
    precio: 210,
    clave: "95",
  },
  {
    id: "l18",
    concepto: "Probabilidad y Estadística",
    precio: 220,
    clave: "94",
  },
  {
    id: "l19",
    concepto:
      "Conciencia histórica I. Perspectivas de México antiguo en los contextos globales",
    precio: 240,
    clave: "93",
  },
  {
    id: "l20",
    concepto: "Logística Modulo III",
    precio: 240,
    clave: "93",
  },
  {
    id: "l21",
    concepto: "Logística Módulo V",
    precio: 240,
    clave: "93",
  },
  {
    id: "l22",
    concepto: "Historia Universal Contemporánea",
    precio: 240,
    clave: "93",
  },
  {
    id: "l23",
    concepto: "Matemáticas Aplicadas",
    precio: 240,
    clave: "93",
  },
  {
    id: "l24",
    concepto: "Introducción a la bioquímica",
    precio: 240,
    clave: "93",
  },
  {
    id: "l25",
    concepto: "Temas de ciencias de la salud",
    precio: 240,
    clave: "93",
  },
];

export default conceptos;
