import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import "./FormularioExamen.css";
import ReferenciaOnline from "../utilities/ReferenciaOnline";

const FormularioExamen = ({
  alumno,
  precio,
  cambiarPrecio,
  cambiarDatosPago,
  cambiarTablaConceptos,
}) => {
  const [checked, cambiarChecked] = useState({ examenes: "0", cursos: "0" });
  const [error, cambiarError] = useState(false);
  const [pagar, cambiarPagar] = useState(false);

  const HandleOnChange = (e) => {
    cambiarChecked({ ...checked, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    cambiarPrecio(0);
    cambiarTablaConceptos([]);
  }, []);

  useEffect(() => {
    let total =
      120 * parseInt(checked.examenes) + 150 * parseInt(checked.cursos);
    cambiarPrecio(total);
  }, [checked]);

  const CrearPago = async () => {
    if (precio === 0) {
      cambiarError(true);
      return;
    }
    let tabla = [];
    let conceptos = "";
    if (checked.examenes > 0) {
      for (let i = 0; i < checked.examenes; i++) {
        conceptos = conceptos.concat("15");
        tabla.push({
          concepto: "Examen Extraordinario",
          clave: "15",
          precio: "120",
        });
      }
    }
    if (checked.cursos > 0) {
      for (let i = 0; i < checked.cursos; i++) {
        conceptos = conceptos.concat("27");
        tabla.push({
          concepto: "Curso Intersemestral",
          clave: "27",
          precio: "150",
        });
      }
    }
    if (conceptos.length < 10) {
      while (conceptos.length < 10) {
        conceptos = conceptos.concat("00");
      }
    }

    cambiarTablaConceptos(tabla);

    //Examen extraordinario clave 15
    //Curso Intersemestral clave 27
    cambiarError(false);

    //Armar referencia
    let importe = precio.toString().concat(".00");
    let refaux = alumno.codigo[7].concat(alumno.codigo[8]);
    refaux = refaux.concat(conceptos);
    refaux = refaux.concat(alumno.codigo);
    let base64 = await ReferenciaOnline(refaux, importe);
    cambiarDatosPago({
      referencia: refaux,
      importe: importe,
      key: base64,
    });

    cambiarPagar(true);
  };

  return alumno.codigo === "" ? (
    <Redirect to="/login" />
  ) : (
    <div className="FormularioExamen container">
      <h2>Selecciona la cantidad de exámenes y cursos</h2>
      <div className="FormularioExamen-flex">
        <div className="FormularioExamen-form">
          <div className="FormularioExamen-examen">
            <p>Examenes Extraordinarios</p>
            <label>
              <input
                type="radio"
                name="examenes"
                value="0"
                checked={checked.examenes === "0"}
                onChange={HandleOnChange}
              />
              0
            </label>
            <label>
              <input
                type="radio"
                name="examenes"
                value="1"
                checked={checked.examenes === "1"}
                onChange={HandleOnChange}
              />
              1
            </label>
            <label>
              <input
                type="radio"
                name="examenes"
                value="2"
                checked={checked.examenes === "2"}
                onChange={HandleOnChange}
              />
              2
            </label>
            <label>
              <input
                type="radio"
                name="examenes"
                value="3"
                checked={checked.examenes === "3"}
                onChange={HandleOnChange}
              />
              3
            </label>
          </div>
          <br />
          <hr />
          <br />
          <div className="FormularioExamen-cursos">
            <p>Cursos Intersemestrales</p>
            <label>
              <input
                type="radio"
                name="cursos"
                value="0"
                checked={checked.cursos === "0"}
                onChange={HandleOnChange}
              />
              0
            </label>
            <label>
              <input
                type="radio"
                name="cursos"
                value="1"
                checked={checked.cursos === "1"}
                onChange={HandleOnChange}
              />
              1
            </label>
            <label>
              <input
                type="radio"
                name="cursos"
                value="2"
                checked={checked.cursos === "2"}
                onChange={HandleOnChange}
              />
              2
            </label>
          </div>
        </div>
        <div className="">
          <div className="FormularioExamen-total">
            <h4>Total: ${precio}.00</h4>
          </div>
          {error ? (
            <div className="Formulario-error">
              <p>Selecciona una opción</p>
            </div>
          ) : null}
          <input
            className="Formulario-boton"
            type="button"
            onClick={CrearPago}
            value="Pagar"
          />
        </div>
      </div>
      {pagar ? <Redirect to="/formadepago" /> : null}
    </div>
  );
};

export default FormularioExamen;
