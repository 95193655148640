import React, { useState, Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";

import Header from "./components/Header";
import Opciones from "./components/Opciones";
import Login from "./components/Login";
import Tipo from "./components/Tipo";
import Formulario from "./components/Formulario";
import FormularioExamen from "./components/FormularioExamen";
import FormularioDiplomas from "./components/FormularioDiplomas";
import FormularioLibros from "./components/FormularioLibros";
import FormaPago from "./components/FormaPago";

function App() {
  const [checked, cambiarChecked] = useState({
    c1: false,
    c2: false,
    c3: false,
    c4: false,
    c5: false,
    c6: false,
    c7: false,
    c8: false,
    c9: false,
    c10: false,
    c11: false,
    c12: false,
    c13: false,
    c14: false,
    c15: false,
    c16: false,
    c17: false,
    c18: false,
    c19: false,
    c20: false,
    c21: false,
    c22: false,
  });

  const conceptos = [
    {
      id: "c1",
      checked: checked.c1,
      clave: "01",
      concepto: "Constancia de Estudios",
      precio: 55,
    },
    {
      id: "c2",
      checked: checked.c2,
      clave: "02",
      concepto: "Constancia de Estudios con Calificaciones",
      precio: 100,
    },
    {
      id: "c3",
      checked: checked.c3,
      clave: "03",
      concepto: "Certificado de parcial estudios",
      precio: 120,
    },
    {
      id: "c4",
      checked: checked.c4,
      clave: "04",
      concepto: "Certificado de terminación de estudios",
      precio: 250,
    },
    {
      id: "c5",
      checked: checked.c5,
      clave: "05",
      concepto: "Carta de buena conducta",
      precio: 120,
    },
    {
      id: "c6",
      checked: checked.c6,
      clave: "10",
      concepto: "Paquete de nuevo ingreso (Examen de ingreso College Board)",
      precio: 500,
    },
    {
      id: "c7",
      checked: checked.c7,
      clave: "45",
      concepto: "Título y Acta de recepción profesional",
      precio: 500,
    },
    {
      id: "c8",
      checked: checked.c8,
      clave: "13",
      concepto: "Carta de pasante (Enfermería general)",
      precio: 70,
    },
    {
      id: "c9",
      checked: checked.c9,
      clave: "14",
      concepto: "Reposición de certificado de estudios",
      precio: 200,
    },
    {
      id: "c10",
      checked: checked.c10,
      clave: "16",
      concepto: "Historial académico",
      precio: 120,
    },
    {
      id: "c11",
      checked: checked.c11,
      clave: "29",
      concepto: "Holograma",
      precio: 60,
    },
    {
      id: "c12",
      checked: checked.c12,
      clave: "31",
      concepto: "Credencial de alumno con holograma",
      precio: 70,
    },
    {
      id: "c13",
      checked: checked.c13,
      clave: "35",
      concepto: "Gastos de administración (semestral)",
      precio: 1100,
    },
    {
      id: "c14",
      checked: checked.c14,
      clave: "36",
      concepto:
        "Predictamen para trámite de revalidación o equivalencia de estudios",
      precio: 50,
    },
    {
      id: "c15",
      checked: checked.c15,
      clave: "37",
      concepto: "Constancia de No Adeudo (sexto semestre)",
      precio: 35,
    },
    {
      id: "c16",
      checked: checked.c16,
      clave: "38",
      concepto: "Constancia de liberación de servicio social",
      precio: 55,
    },
    {
      id: "c17",
      checked: checked.c17,
      clave: "39",
      concepto: "Fortalecimiento académico mediante el uso de plataformas",
      precio: 50,
    },
    {
      id: "c18",
      checked: checked.c18,
      clave: "40",
      concepto: "Reimpresión de título",
      precio: 150,
    },
    {
      id: "c19",
      checked: checked.c19,
      clave: "42",
      concepto: "Kardex firmado y sellado",
      precio: 50,
    },
    {
      id: "c20",
      checked: checked.c20,
      clave: "43",
      concepto: "Cambio de carrera",
      precio: 100,
    },
    {
      id: "c21",
      checked: checked.c21,
      clave: "44",
      concepto: "Evaluación especial",
      precio: 100,
    },

    // {
    //   id: "c22",
    //   checked: checked.c22,
    //   clave: "11",
    //   concepto: "Certificado de Terminación de estudios",
    //   precio: 250,
    // },
    // {
    //   id: "c11",
    //   checked: checked.c11,
    //   clave: "15",
    //   concepto: "Examen extraordinario",
    //   precio: 120,
    // },
    // {
    //   id: "c13",
    //   checked: checked.c13,
    //   clave: "17",
    //   concepto: "Manual propedéutico",
    //   precio: 45,
    // },
    // {
    //   checked: checked.c14,
    //   id: "c14",
    //   clave: "27",
    //   concepto: "Curso intersemestral",
    //   precio: 150,
    // },
    // {
    //   id: "c17",
    //   checked: checked.c17,
    //   clave: "34",
    //   concepto: "Acta de recepción profesional",
    //   precio: 120,
    // },
  ];

  const [pagar, cambiarPagar] = useState(false);

  const [tablaConceptos, cambiarTablaConceptos] = useState([]);

  const [alumno, modificarAlumno] = useState({
    codigo: "",
    //20414073010288
    nombre: "",
  });
  const [precio, cambiarPrecio] = useState(0);
  const [datospago, cambiarDatosPago] = useState({
    referencia: "",
    importe: "",
    key: "",
  });
  const [claves, cambiarClaves] = useState([]);
  const [login, cambiarLogin] = useState(false);
  //<Route exact path="/Formulario" render={} />
  return (
    <BrowserRouter>
      <Header
        alumno={alumno}
        modificarAlumno={modificarAlumno}
        login={login}
        cambiarLogin={cambiarLogin}
        cambiarChecked={cambiarChecked}
        cambiarPagar={cambiarPagar}
        cambiarPrecio={cambiarPrecio}
        cambiarClaves={cambiarClaves}
      />

      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Opciones
              alumno={alumno}
              modificarAlumno={modificarAlumno}
              cambiarLogin={cambiarLogin}
              cambiarDatosPago={cambiarDatosPago}
            />
          )}
        />
        <Route
          exact
          path="/login"
          render={() => (
            <Login
              alumno={alumno}
              modificarAlumno={modificarAlumno}
              cambiarLogin={cambiarLogin}
              cambiarDatosPago={cambiarDatosPago}
            />
          )}
        />
        <Route
          exact
          path="/tipo"
          render={() => (
            <Tipo
              conceptos={conceptos}
              alumno={alumno}
              precio={precio}
              cambiarPrecio={cambiarPrecio}
              datospago={datospago}
              cambiarDatosPago={cambiarDatosPago}
              claves={claves}
              cambiarClaves={cambiarClaves}
              checked={checked}
              cambiarChecked={cambiarChecked}
              cambiarPagar={cambiarPagar}
              cambiarTablaConceptos={cambiarTablaConceptos}
            />
          )}
        />
        <Route
          exact
          path="/formulario-libros"
          render={() => (
            <FormularioLibros
              conceptos={conceptos}
              alumno={alumno}
              precio={precio}
              cambiarPrecio={cambiarPrecio}
              datospago={datospago}
              cambiarDatosPago={cambiarDatosPago}
              claves={claves}
              cambiarClaves={cambiarClaves}
              checked={checked}
              cambiarChecked={cambiarChecked}
              cambiarPagar={cambiarPagar}
              pagar={pagar}
              tablaConceptos={tablaConceptos}
              cambiarTablaConceptos={cambiarTablaConceptos}
            />
          )}
        />
        <Route
          exact
          path="/formulario-otros"
          render={() => (
            <Formulario
              conceptos={conceptos}
              alumno={alumno}
              precio={precio}
              cambiarPrecio={cambiarPrecio}
              datospago={datospago}
              cambiarDatosPago={cambiarDatosPago}
              claves={claves}
              cambiarClaves={cambiarClaves}
              checked={checked}
              cambiarChecked={cambiarChecked}
              cambiarPagar={cambiarPagar}
              pagar={pagar}
              tablaConceptos={tablaConceptos}
              cambiarTablaConceptos={cambiarTablaConceptos}
            />
          )}
        />
        <Route
          exact
          path="/formulario-examenes"
          render={() => (
            <FormularioExamen
              precio={precio}
              cambiarPrecio={cambiarPrecio}
              alumno={alumno}
              cambiarDatosPago={cambiarDatosPago}
              tablaConceptos={tablaConceptos}
              cambiarTablaConceptos={cambiarTablaConceptos}
            />
          )}
        />
        <Route
          exact
          path="/formulario-diplomas"
          render={() => (
            <FormularioDiplomas
              precio={precio}
              cambiarPrecio={cambiarPrecio}
              alumno={alumno}
              cambiarDatosPago={cambiarDatosPago}
              tablaConceptos={tablaConceptos}
              cambiarTablaConceptos={cambiarTablaConceptos}
            />
          )}
        />
        <Route
          exact
          path="/formadepago"
          render={() => (
            <FormaPago
              alumno={alumno}
              datospago={datospago}
              tablaConceptos={tablaConceptos}
              cambiarTablaConceptos={cambiarTablaConceptos}
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
